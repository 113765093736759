import { z } from "zod";
import { Species } from "spot-types/entities/Species";
import { MarketingSchema, PetSchema } from "@/shared/types/Quote.interface";
import { CA_MAX_AGE_CAT, CA_MAX_AGE_DOG } from "../utils/constants";
import { RegexUtils } from "@/shared/utils/RegexUtils";
import Strings from "@/shared/utils/Strings.constants";
import { UIUtils } from "@/shared/utils/UIUtils";

const RefinedPetSchema = PetSchema.extend({
    age: z.number().optional(),
    birthMonth: z.string().min(1, Strings.ERRORS.BIRTH_MONTH),
    birthYear: z.string().min(4, Strings.ERRORS.BIRTH_YEAR)
}).refine(
    data => {
        const birthYear = parseInt(data.birthYear, 10);
        const birthMonth = parseInt(data.birthMonth, 10);
        const today = new Date();
        const birthDate = new Date(birthYear, birthMonth - 1, 1); // Month is 0-indexed in Date constructor

        // Check if birth date is in the future
        if (birthDate > today) {
            return false;
        }

        // Check if birth date is within the last 8 weeks
        const minimumAge = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        if (birthYear === today.getFullYear() && birthDate > minimumAge) {
            return false;
        }

        // Calculate the difference in years between the current year and the birth year
        let age = today.getFullYear() - birthYear;

        // Check if the current month is earlier than the birth month,
        // or if it's the same month but the current date is before the 1st.
        // If so, subtract 1 from the age since the birthday hasn't occurred yet this year.
        const hasBirthdayPassedThisYear = today.getMonth() + 1 > birthMonth || (today.getMonth() + 1 === birthMonth && today.getDate() >= 1);

        if (!hasBirthdayPassedThisYear) {
            age -= 1;
        }

        switch (data.species) {
            case Species.Cat:
                return age < CA_MAX_AGE_CAT;
            case Species.Dog:
                return age < CA_MAX_AGE_DOG;
            default:
                return true;
        }
    },
    data => {
        const birthYear = parseInt(data.birthYear, 10);
        const birthMonth = parseInt(data.birthMonth, 10);
        const today = new Date();
        const birthDate = new Date(birthYear, birthMonth - 1, 1);

        if (birthDate > today) {
            return {
                message: "Birth date cannot be in the future.",
                path: ["birthYear"]
            };
        }

        const minimumAge = new Date(today.getFullYear(), today.getMonth() - 2, 1);
        if (birthYear === today.getFullYear() && birthDate > minimumAge) {
            return {
                message: Strings.ERRORS.PET_MIN_AGE,
                path: ["birthMonth"]
            };
        }

        const speciesName = data.species === Species.Cat ? "Cat" : "Dog";
        const maxAge = data.species === Species.Cat ? CA_MAX_AGE_CAT : CA_MAX_AGE_DOG;
        return {
            message: `For ${maxAge}+ year-old ${speciesName.toLowerCase()}s, please call ${UIUtils.formatPhone(Strings.PTZ_CA.PHONE_NUMBER)} for a specialized quote.`,
            path: ["birthYear"]
        };
    }
);

const PartialCaPetSchema = PetSchema.deepPartial().refine(
    data => {
        if (data.age === null || data.age === undefined) {
            return true; // Skip further validation if age is null, since the previous refine already catches null values for actual validation
        }

        switch (data.species) {
            case Species.Cat:
                return data.age < CA_MAX_AGE_CAT;
            case Species.Dog:
                return data.age < CA_MAX_AGE_DOG;
            default:
                return true;
        }
    },
    data => {
        if (data.age === null || data.age === undefined) {
            return {}; // No error message for null, as it's already handled
        }

        const speciesName = data.species === Species.Cat ? "Cat" : "Dog";
        const maxAge = data.species === Species.Cat ? CA_MAX_AGE_CAT : CA_MAX_AGE_DOG;
        return {
            message: `For ${maxAge}+ year-old ${speciesName.toLowerCase()}s, please call 1-888-501-7768 for a specialized quote.`,
            path: ["age"]
        };
    }
);

export const PartialCaPetPoliciesSchema = z.object({
    policies: z.array(PartialCaPetSchema)
});
export type PartialCaPetPolicies = z.infer<typeof PartialCaPetPoliciesSchema>;

export const CaPetPoliciesSchema = z.object({
    policies: z.array(RefinedPetSchema)
});
export type CaPetPolicies = z.infer<typeof CaPetPoliciesSchema>;
// Step Schema

export const PolicyStepSchema = z.object({
    email: z.string().email(`Enter a valid email address`),
    ratingZipcode: z.string().regex(RegexUtils.postalCodeCARegex, Strings.ERRORS.POSTAL_CODE),
    policies: z.array(RefinedPetSchema),
    marketing: MarketingSchema,
    phone: z
        .string()
        .refine(value => value === "" || RegexUtils.phoneRegex.test(value), "Enter a valid mobile number")
        .optional(),
    extra: z.object({
        policyStepConsented: z.coerce.boolean().refine(value => value === true, {
            message: "Please agree to contact consent"
        })
    })
});

export const PartialPolicyStepSchema = PolicyStepSchema.extend({ policies: z.array(PetSchema) }).deepPartial();
export type PartialPolicyStepType = z.infer<typeof PartialPolicyStepSchema>;
export type PolicyStepType = z.infer<typeof PolicyStepSchema>;
